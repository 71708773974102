import React from 'react'
import ServiceSidebar from './ServiceSidebar'
import details1 from '../../../assets/images/services/service-managed.jpg'

const ServiceDetailsContent = () => {
    return (
        <section className="services-details-area ptb-100">
            <div className="container">
                <div className="row">
                    <div className="col-lg-9 col-md-12">
                        <div className="services-details-image">
                            <img src={details1} alt="about" />
                        </div>

                        <div className="services-details-desc">
                            <h3 id="data-protection">Data Protection</h3>
                            <p>
                                We help enterprises conquer data protection challenges with less cost, risk and complexity.
                                With a single software solution, you can efficiently protect, manage and access your critical
                                data across any storage tier while eliminating risk and the impact to production systems.
                            </p>

                            <h3 id="it-consultancy">IT Professional & Consultancy Services</h3>
                            <p>
                                Cobranet has a team with a wide range of IT expertise, a team that can help you from requirement
                                gathering and defining to IT infrastructure strategy, we provide a full range of IT consultancy
                                services and support to help take the hassles out of your IT while you focus on your business
                                development and operation.
                            </p>

                            <h3 id="communication">Unified Communications & Collaboration</h3>
                            <p>
                                We bring together the communications tools that people use daily such as voice, e-mail, instant
                                messaging, presence, click-to-dial, conferencing and video into a single interface. UC&C helps
                                overcome the inefficiencies and challenges in organizations with fragmented communications.
                            </p>

                            <h3 id="interbranching">Interbranching</h3>
                            <p>
                                The flexibility of our network offers a wide variety of last mile options to all ranges of
                                businesses, depending on the locations, as well as the redundancy that the client requires to
                                increase link reliability.
                            </p>

                            <h3 id="security">Security</h3>
                            <p>
                                Intrusion detection and prevention systems (IDPS), are security appliances that monitor network
                                and system activities for malicious activity by identifying, logging, attempting to block and
                                reporting such activity. We assess your existing network scenario and proffer solutions that
                                keep you protected even from the most evolved threats.
                            </p>
                        </div>
                    </div>

                    <div className="col-lg-3 col-md-12">
                        <ServiceSidebar />
                    </div>
                </div>
            </div>
        </section>
    )
}

export default ServiceDetailsContent