import React from 'react'
import { AnchorLink } from "gatsby-plugin-anchor-links"

const ServiceSidebar = () => {
    const myPage = 'service-managed-details';
    return (
        <div className="services-details-info">
            <ul className="services-list">
                <li>
                    <AnchorLink to={`/${myPage}#data-protection`} title="Data Protection">
                        <span>Data Protection</span>
                    </AnchorLink>
                </li>
                <li>
                    <AnchorLink to={`/${myPage}#it-consultancy`} title="IT Professional & Consultancy Services">
                        <span>IT Professional & Consultancy Services</span>
                    </AnchorLink>
                </li>
                <li>
                    <AnchorLink to={`/${myPage}#communication`} title="Unified Communications & Collaboration">
                        <span>Unified Communications & Collaboration</span>
                    </AnchorLink>
                </li>
                <li>
                    <AnchorLink to={`/${myPage}#interbranching`} title="Interbranching">
                        <span>Interbranching</span>
                    </AnchorLink>
                </li>
                <li>
                    <AnchorLink to={`/${myPage}#security`} title="Security">
                        <span>Security</span>
                    </AnchorLink>
                </li>

            </ul>
        </div>
    )
}

export default ServiceSidebar